<template>
  <div class="width-sm contents contents-rendered">
    <ContentRenderer
      v-for="(content, index) in parsedContents"
      :content="content"
      :key="index"
    />
  </div>
</template>

<script lang="ts">
import ContentRenderer from "@/components/ContentRenderer";

export default {
  name: "RenderHtml",
  props: {
    contents: {
      required: true,
      type: String,
    },
  },
  components: {
    ContentRenderer,
  },
  computed: {
    parsedContents() {
      let theLines = this.contents.split("\n");
      const lines = [];

      const collapsibleRegex = /<div londo-collapsible(.*?)>/;
      const ulRegex = /<ul>/;
      const olRegex = /<ol>/;

      let i = 0;
      while (i < theLines.length) {
        const matchesUl = ulRegex.test(theLines[i]);
        const matchesOl = olRegex.test(theLines[i]);

        if (theLines[i] === "<blockquote>") {
          lines.push(
            theLines[i] + (theLines[i + 1] || "") + (theLines[i + 2] || "")
          );

          i += 3;
        } else if (collapsibleRegex.test(theLines[i])) {
          let end = false;
          let childLines = [theLines[i]];
          let j = i + 1;
          while (!end) {
            for (j; j <= theLines.length; j++) {
              if (theLines[j] === "</div londo-collapsible>") {
                childLines.push("</londo-collapsible>");
                end = true;
                break;
              }
              childLines.push(theLines[j]);
            }
          }
          lines.push(childLines.join(""));
          i = j + 1;
        } else if (matchesUl || matchesOl) {
          let end = false;
          let childLines = [theLines[i]];
          let j = i + 1;
          while (!end) {
            for (j; j <= theLines.length; j++) {
              if (theLines[j] === "</ul>") {
                childLines.push("</ul>");
                end = true;
                break;
              }
              if (theLines[j] === "</ol>") {
                childLines.push("</ol>");
                end = true;
                break;
              }
              childLines.push(theLines[j]);
            }
          }
          lines.push(childLines.join(""));
          i = j + 1;
        } else {
          lines.push(theLines[i]);
          i++;
        }
      }

      return lines.map((line) => {
        const matchCarousel = line.match(/<div londo-swipe-carousel(.*?)\/>/);
        if (matchCarousel) {
          return {
            isComponent: true,
            name: "londo-swipe-carousel",
            props: this.extractProps(matchCarousel[1]),
          };
        }
        const matchBlogCarousel = line.match(
          /<div londo-blog-carousel(.*?)\/>/
        );
        if (matchBlogCarousel) {
          return {
            isComponent: true,
            name: "londo-blog-carousel",
            props: this.extractProps(matchBlogCarousel[1]),
          };
        }
        const matchSchedule = line.match(/<div londo-schedule(.*?)\/>/);
        if (matchSchedule) {
          return {
            isComponent: true,
            name: "londo-schedule",
            props: this.extractProps(matchSchedule[1]),
          };
        }
        const matchCard = line.match(/<div londo-card(.*?)\/>/);
        if (matchCard) {
          return {
            isComponent: true,
            name: "londo-card",
            props: this.extractProps(matchCard[1]),
          };
        }
        const matchCollapsible = line.match(/<div londo-collapsible(.*?)/);
        if (matchCollapsible) {
          const matchCollapsibleForProps = line.match(
            /<div londo-collapsible(.*?)>/
          );
          let props = { title: "", content: "" };
          if (matchCollapsibleForProps) {
            props = this.extractProps(matchCollapsibleForProps[1]);
          }

          const innerContentStart = line.indexOf(">") + 1;
          const innerContentEnd = line.lastIndexOf("</londo-collapsible>");
          const innerContent = line
            .substring(innerContentStart, innerContentEnd)
            .trim();

          // Process the inner content to detect any nested components
          const processedInnerContent = this.parseInnerContent(innerContent);

          return {
            isComponent: true,
            name: "londo-collapsible",
            props: {
              ...props,
            },
            children: processedInnerContent,
          };
        }

        return {
          isComponent: false,
          rawHTML: line,
        };
      });
    },
  },
  methods: {
    extractProps(propsString) {
      const props = {};
      const propPattern = /:(\w+)="([^"]+)"/g;
      const altPropPattern = /(\w+)="([^"]+)"/g;

      let match;
      while ((match = propPattern.exec(propsString)) !== null) {
        props[match[1]] = JSON.parse(match[2]);
      }
      while ((match = altPropPattern.exec(propsString)) !== null) {
        props[match[1]] = match[2];
      }
      return props;
    },
    parseInnerContent(content) {
      const londoSchedulePattern = /<div londo-schedule\s*([^>]*)>([\s\S]*?)<\/div\s*londo-schedule>/g;

      let match;
      const schedules = [];

      // Use a while loop with RegExp.exec() to extract all matches
      while ((match = londoSchedulePattern.exec(content)) !== null) {
        const propsString = match[1];
        const innerHTMLContent = match[2].trim(); // This captures the slot content.
        const props = this.extractProps(propsString);

        const processedInnerContent = this.parseInnerContent(innerHTMLContent);

        schedules.push({
          isComponent: true,
          name: "londo-schedule",
          props,
          children: processedInnerContent,
        });
      }

      if (schedules.length > 0) {
        return schedules;
      }

      return content; // Return as raw HTML if no nested component detected
    },
  },
};
</script>
