/* eslint-disable max-len */
export default {
  data() {
    return {
      scrollThresholds: [0.5, 0.75, 0.9, 1],
      thresholdsReached: {
        threshold50: false,
        threshold75: false,
        threshold90: false,
        threshold100: false,
      },
      eventFired: {}
    };
  },
  methods: {
    pushScrollEventToDataLayer(percentage) {
      const eventObj = {
        event: "scrollThresholdReached",
        scrollPercentage: `${percentage * 100}%`,
      };

      this.trackEvent(eventObj);
    },
    handleScroll() {
      const appContent = document.getElementById("app-content");
      if (!appContent) return;
      const { scrollTop, scrollHeight, clientHeight } = appContent;
      const scrollPercentage = scrollTop / (scrollHeight - clientHeight);

      const thresholdsKeys = [
        "threshold50",
        "threshold75",
        "threshold90",
        "threshold100",
      ];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.scrollThresholds.length; i++) {
        const thresholdKey = thresholdsKeys[i];
        const thresholdValue = this.scrollThresholds[i];

        if (
          !this.thresholdsReached[thresholdKey] &&
          scrollPercentage >= thresholdValue &&
          !this.eventFired[thresholdKey]
        ) {
          this.thresholdsReached[thresholdKey] = true;
          this.pushScrollEventToDataLayer(thresholdValue);
          this.eventFired[thresholdKey] = true;
        }
      }
    },
    resetThresholdsReached() {
      this.thresholdsReached.threshold50 = false;
      this.thresholdsReached.threshold75 = false;
      this.thresholdsReached.threshold90 = false;
      this.thresholdsReached.threshold100 = false;
      this.eventFired = {};
    },
    setupScrollTracker() {
      const appContent = document.getElementById("app-content");
      if (appContent) {
        appContent.addEventListener("scroll", this.handleScroll);
      }
    },
    removeScrollTracker() {
      const appContent = document.getElementById("app-content");
      if (appContent) {
        appContent.removeEventListener("scroll", this.handleScroll);
      }
    },
  },
  mounted() {
    this.resetThresholdsReached();
    this.setupScrollTracker();
  },
  beforeDestroy() {
    this.removeScrollTracker();
  },
  watch: {
    '$route'() {
      this.removeScrollTracker();
      this.resetThresholdsReached();
    }
  }
};

