<!-- eslint-disable max-len -->
<template>
  <div
    class="bottom-video relative overflow-hidden fade-in animation-delay-100"
  >
    <div
      class="bottom-video__responsive overflow-hidden relative"
      ref="bottomVideo"
      data-rellax-percentage="0.5"
      data-rellax-xs-speed="-0.5"
      data-rellax-speed="-1"
      data-rellax-desktop-speed="-2.5"
    >
      <iframe
        :id="`v${videoId}`"
        ref="iframe"
        :src="`https://player.vimeo.com/video/${videoId}?background=1&autoplay=0&loop=1`"
        width="640"
        height="360"
        frameborder="0"
        allow="autoplay"
        class="absolute top-0 left-0 h-full w-full z-0"
      ></iframe>
    </div>
    <div v-if="gradientOverlay" class="bottom-video__overlay"></div>
    <transition name="fade" v-if="!videoStatus && $device.ios">
      <button
        class="bottom-video__btn btn btn-default btn-icon btn-icon-only btn-inverse absolute-center m-0"
        @click="playerPlay()"
      >
        <i class="icon-play"></i>
      </button>
    </transition>
  </div>
</template>

<script>
export default {
  name: "LondoBottomVideo",
  props: {
    gradientOverlay: {
      type: Boolean,
      default: true,
    },
    videoId: {
      type: String,
      required: true,
    },
    preloadVideo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      prePlayVideoOnPageLoad: false,
      observer: null,
      intersectionObserverOptions: {
        root: document.querySelector("#app-content"),
        rootMargin: "0px",
        threshold: 0,
      },
      videoInView: false,
      videoStatus: null,
    };
  },
  computed: {
    menuOpen() {
      return this.$store.state.modifiers.drawerOpen;
    },
  },
  components: {},
  watch: {
    menuOpen(val) {
      this.pauseObserver(val);
    },
  },
  methods: {
    pauseObserver(pause) {
      if (!this.observer) return;
      if (pause) {
        console.log(
          "%c%s",
          "color:lightBlue;",
          "pause bottomVideo observer and video"
        );
        this.observer.unobserve(this.$refs.bottomVideo);
        this.playerPause();
      } else {
        console.log("%c%s", "color:lightBlue;", "unpause bottomVideo observer");
        this.observer.observe(this.$refs.bottomVideo);
      }
    },
    playIfPaused() {
      this.player.getPaused().then((paused) => {
        if (paused) {
          this.playerPlay();
        }
      });
    },
    playerSet() {
      this.player = new this.$Vimeo(`v${this.videoId}`);
      this.player.ready().catch((e) => {
        console.log("vimeo error", e);

        this.$emit("vimeo-error");
      });

      this.player.on("timeupdate", (data) => {
        this.playerTimeUpdate(data);
      });

      this.player.on("play", () => {
        console.log("%c%s", "color:lightBlue;", "play video:", this.videoId);
        if (!this.videoStatus) {
          this.videoStatus = "has-played";
        }
      });

      console.log(
        "%c%s",
        "color:lightBlue;",
        "preload video is",
        this.preloadVideo
      );
      if (this.preloadVideo || !this.observer) {
        this.playerPlay();
      }
    },
    playerPlay() {
      this.player.play();
    },
    playerPause() {
      if (this.videoPlaybackStarted) {
        console.log("%c%s", "color:lightBlue;", "pause video");
        this.player.pause();
      }
    },
    playerTimeUpdate(data) {
      if (!this.videoPlaybackStarted) {
        console.log(
          "%c%s",
          "color:lightBlue;",
          "video playing for first time:"
        );
        this.videoPlaybackStarted = true;

        if (this.observer && !this.videoInView) {
          console.log("%c%s", "color:lightBlue;", "video not in view, pause");
          this.playerPause();
        }
      }
      return false;
    },
  },
  mounted() {
    if (typeof window !== "undefined" && window.IntersectionObserver) {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log("%c%s", "color:lightBlue;", "video in view");
            this.videoInView = true;
            this.playIfPaused();
          } else {
            console.log("%c%s", "color:lightBlue;", "video not in view");
            this.videoInView = false;
            this.playerPause();
          }
        });
      }, this.intersectionObserverOptions);

      this.observer.observe(this.$refs.bottomVideo);
    }

    this.playerSet();
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>
<style scoped lang="scss">
.bottom-video {
  &__responsive {
    padding-top: 56.25%;
    z-index: -1;
    :deep(iframe) {
      @apply absolute top-0 left-0 h-full w-full z-0;
    }
  }
  &__overlay {
    @include overlay-bottom-image;
  }
  &__btn {
    width: 48px;
    height: 48px;
    &.btn-default.btn-inverse {
      background: rgba(0, 0, 0, 0.4);
    }
  }
}
</style>
