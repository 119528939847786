import store from "@/store";

export function useViewTransitionFlags(
  viewLeaving,
  viewLeavingAnimationComplete
) {
  const closeOpenGallery = () => {
    //find open gallery and close
    const openGalleryId = Object.keys(window.lgData).filter(
      (id) => window.lgData[id].lGalleryOn
    )[0];
    window.lgData[openGalleryId].destroy();
    console.log("%c%s", "color: GoldenRod;", "closed gallery", openGalleryId);
  };

  const handleBackButton = () => {
    if (window.event.type == "popstate") {
      console.log("%c%s", "color: GoldenRod;", "handleBackButton popstate");

      let preventNav = false;
      let openFeature = "gallery";

      //gallery
      if (store.state.modifiers.galleryOpen) {
        closeOpenGallery();
        preventNav = true;
      }
      //drawer
      else if (store.state.modifiers.drawerOpen) {
        openFeature = "drawer";
        store.commit("toggleDrawerState");
        preventNav = true;
      }

      if (preventNav) {
        console.log(
          "%c%s",
          "color: GoldenRod;",
          `navigating back and ${openFeature} open, close gallery, prevent navigation`
        );
        next(false);
      }
    }
  };

  const togglePageDataReady = (isReady = true) => {
    console.log(
      "%c%s",
      "color: OrangeRed;",
      "toggleModifier pageDataReady",
      isReady
    );
    store.commit("toggleModifier", { key: "pageDataReady", val: isReady });
  };

  const toggleViewLeaving = (log) => {
    handleBackButton();
    console.log("%c%s", "color: DarkSeaGreen;", "toggleViewLeaving", ...log);
    //close drawer if open
    if (store.state.modifiers.drawerOpen) {
      store.commit("toggleDrawerState");
    }

    togglePageDataReady(false);
    viewLeaving.value = true;
    setTimeout(() => {
      viewLeavingAnimationComplete.value = true;
    }, 50);
  };

  return {
    togglePageDataReady,
    toggleViewLeaving,
  };
}
