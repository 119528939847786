<template>
  <div
    class="bottom-image overflow-hidden relative fade-in animation-delay-100"
  >
    <img
      :src="src"
      :srcset="srcset"
      :sizes="$constants.images.sizes.bottom"
      :alt="alt"
      ref="bottomImage"
      data-rellax-percentage="0.5"
      data-rellax-xs-speed="-0.5"
      data-rellax-speed="-1"
      data-rellax-desktop-speed="-2.5"
    />
    
    <div v-if="gradientOverlay" class="bottom-image__overlay"></div>
  </div>
</template>

<script>
export default {
  name: "LondoBottomImage",
  props: {
    image: {
      type: [Object, Boolean],
      required: true,
    },
    gradientOverlay: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    srcset() {
      return this.image.srcset
        ? this.image.srcset
        : this.$constants.images.fallback.bottom.srcset;
    },
    src() {
      return this.image.sizes
        ? this.image.sizes.large
        : this.$constants.images.fallback.bottom.src;
    },
    alt() {
      return this.image.alt ? this.image.alt : "";
    },
  },
};
</script>

<style scoped lang="scss">
.bottom-image {
  img {
    width: 100%;
  }
  &__overlay {
    @include overlay-bottom-image;
  }
}
</style>
