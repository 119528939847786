<template>
  <!-- If content represents a component -->
  <component v-if="content.isComponent" :is="content.name" v-bind="parsedProps">
    <!-- If single child and child is object, render child component in the slot of parent -->
    <ContentRenderer
      v-if="
        content.children &&
        typeof content.children === 'object' &&
        !Array.isArray(content.children)
      "
      :content="content.children"
    />

    <!-- If multiple children, iterate over them -->
    <template
      v-if="content.children && Array.isArray(content.children)"
      v-for="(child, index) in content.children"
      :key="index"
    >
      <!-- If child is an object, render as component -->
      <ContentRenderer v-if="typeof child === 'object'" :content="child" />
      <!-- If child is a string, render as plain text -->
      <div v-else v-html="child"></div>
    </template>

    <!-- If single child and child is a string, render as slot to parent -->
    <div
      v-if="content.children && typeof content.children === 'string'"
      v-html="content.children"
    ></div>
  </component>

  <!-- If content is raw HTML -->
  <div v-else v-html="content.rawHTML"></div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    parsedProps() {
      let props = { ...this.content.props };
      if (props.cards && typeof props.cards === "string") {
        try {
          props.cards = JSON.parse(props.cards);
        } catch (e) {
          console.error("Failed to parse cards prop:", e);
        }
      }
      return props;
    },
  },
};
</script>
