import { ref, onMounted, onBeforeUnmount } from "vue";
import Rellax from "rellax";
import { useDevice } from "@/composables/useDevice";

export function useParallax() {
  const { device } = useDevice();

  const scrollElement = ref(null);
  const parallax = ref({});
  const parallaxRefs = ref(["parallaxItem"]);
  const parallaxRefreshOnResize = ref(false);

  const parallaxOptions = {
    breakpoints: [480, 768, 1024],
    wrapper: "#app-content",
  };

  const isDisabled = () => {
    let disabled =
      device.ios || device.ipad || device.iphone || device.ipod ? true : false;
    return disabled;
  };

  const createParallax = (refs, options = parallaxOptions) => {
    if (isDisabled()) return;
    parallaxRefs.value = refs;
    parallaxRefs.value.forEach((refVal) => {
      //if (refVal.value && !parallax.value[refName]) {
      if (refVal.value) {
        let instanceName = refVal.value.className;
        console.log(
          "%c%s",
          "color:SeaGreen;",
          "create parallax:",
          instanceName
        );
        parallax.value[instanceName] = new Rellax(refVal.value, options);
      }
    });
  };

  const refreshParallax = () => {
    //only refreshes active instances
    if (isDisabled()) return;
    if (parallax.value) {
      Object.keys(parallax.value).forEach((instance) => {
        console.log(
          "%c%s",
          "color: teal;",
          "refresh parallax instance:",
          instance
        );
        parallax.value[instance].refresh();
      });
    }
  };

  const destroyParallax = () => {
    //disables all instances
    if (isDisabled()) return;
    if (parallax.value) {
      Object.keys(parallax.value).forEach((instance) => {
        console.log(
          "%c%s",
          "color: PaleVioletRed;",
          "destroy parallax instance",
          instance
        );
        parallax.value[instance].destroy();
      });
    }
  };

  const onResize = () => {
    if (isDisabled()) return;
    console.log("%c%s", "color: green;", "onResize useParallax");
    if (parallaxRefreshOnResize.value) {
      //if need to do any parallax changes on resize
      refreshParallax();
    }
  };

  onMounted(() => {
    if (!isDisabled()) {
      if (parallaxRefreshOnResize.value) {
        window.addEventListener("resize", onResize);
      }
      scrollElement.value = document.getElementById("app-content");
    }
  });

  onBeforeUnmount(() => {
    if (!isDisabled()) {
      if (parallaxRefreshOnResize.value) {
        window.removeEventListener("resize", onResize);
      }
      console.log("%c%s", "color: SandyBrown;", "onBeforeUnmount useParallax");
      destroyParallax();
    }
  });

  return {
    createParallax,
    refreshParallax,
    destroyParallax,
    scrollElement,
    parallax,
    parallaxRefs,
    parallaxOptions,
  };
}
