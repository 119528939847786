<template>
  <div
    class="excerpt width-sm"
    :class="[
      ellipsify ? 'ellipsify' : '',
      pageTypeClass,
      { 'excerpt-all-lead': allLeadStyling },
      { 'excerpt--expanded': expanded },
    ]"
  >
    <template v-if="ellipsify && (!grid.md || ellipsifyAllSizes)">
      <ClampedText
        v-if="paragraphs.length"
        class="clamp lead"
        autoresize
        tag="p"
        :maxLines="maxLines"
        :expanded="expanded"
        @clampchange="onClampChange"
      >
        {{ paragraphs[0] }}
      </ClampedText>
      <div v-if="multipleParagraphs" class="remaining">
        <transition-group name="fadeHeight" mode="out-in">
          <p
            v-for="(p, index) in remainingParagraphs"
            :key="index"
            v-show="expanded"
          >
            {{ p }}
          </p>
        </transition-group>
      </div>
      <button
        class="din text-grey-300 btn-read-more block -mt-4"
        @click="expanded = !expanded"
        v-show="hasHiddenContent"
      >
        Read more
      </button>
      <button
        class="din text-grey-300 btn-read-more block -mt-4"
        @click="expanded = !expanded"
        v-show="expanded"
      >
        Show less
      </button>
    </template>
    <template v-else>
      <p
        v-for="(p, index) in paragraphs"
        :key="index"
        :class="{ lead: index == 0 }"
      >
        {{ p }}
      </p>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, toRefs } from "vue";
import ClampedText from "@/components/ClampedText.vue";

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  maxLines: {
    type: Number,
    default: 4,
  },
  ellipsify: {
    type: Boolean,
    default: true,
  },
  pageType: {
    type: String,
    default: null,
  },
  ellipsifyAllSizes: {
    type: Boolean,
    default: false,
  },
  allLeadStyling: {
    type: Boolean,
    default: false,
  },
});

const { text, maxLines, ellipsify } = toRefs(props);

const expanded = ref(false);
const clamped = ref(false);
const paragraphs = ref([]);

const onClampChange = (value) => {
  clamped.value = value;
};

const createArrayFromHtmlString = (text) => {
  const stripped = text.replace(/(<([^>]+)>)/gi, "");
  const array = stripped.split("\n");

  paragraphs.value = array.filter(Boolean);
};

const multipleParagraphs = computed(() => paragraphs.value.length > 1);
const hasHiddenContent = computed(() =>
  expanded.value
    ? false
    : clamped.value || (!clamped.value && multipleParagraphs.value)
);
const remainingParagraphs = computed(() => {
  if (!multipleParagraphs.value) return false;

  return paragraphs.value.filter((x, i) => i !== 0);
});

const pageTypeClass = computed(() =>
  props.pageType ? `excerpt-${props.pageType}` : ""
);

onMounted(() => {
  createArrayFromHtmlString(text.value);
});
</script>

<style scoped lang="scss">
.excerpt {
  &.excerpt-index,
  &.excerpt-home {
    padding-top: 0;
  }
  .btn-read-more {
    @apply relative z-10;
    margin-left: 1.5rem;
    margin-top: -1.3rem;
  }
  &.excerpt-single {
    padding-top: 3rem;
  }
  &.excerpt-all-lead {
    p.lead:first-child {
      @apply mb-6;
      &:after {
        top: 0.4em;
      }
    }
    .remaining {
      @apply italic mt-0 mb-0 overflow-hidden relative z-0 text-grey-700;
      @include left-line;
      &:after {
        top: 0;
        height: calc(100% - 1.8em);
      }
    }
  }
  &--expanded {
    &.excerpt-all-lead {
      p.lead:first-child {
        &:after {
          height: 100%;
        }
      }
      .remaining {
        @apply pt-5 -mt-6;
      }
    }
  }
}
</style>
